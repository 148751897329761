import React from 'react' //, { useState, useEffect }

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

import AdSense from 'react-adsense';
// import loadGAds from '../common/loadGAds';

import Header from '../common/template/header';
import Footer from '../common/template/footer';
import Routes from './routes';

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyDWQ3vjc9ZrNXvU4VFWV6QgAZ-CrLsDWbM",
    authDomain: "alarmclock-af421.firebaseapp.com",
    projectId: "alarmclock-af421",
    storageBucket: "alarmclock-af421.appspot.com",
    messagingSenderId: "85627927796",
    appId: "1:85627927796:web:9c50effdca6c332d89b2a1",
    measurementId: "G-JNJXBRELM3"
};

const App = (props) => {

    // const [loaded, setLoaded] = useState(false);
    // useEffect(() => {
    //     loadGAds(() => {
    //         setLoaded(true);
    //     });
    // });
    // Initialize Firebase
    const app = initializeApp(firebaseConfig);
    if (app.name && typeof window !== 'undefined') {
        getAnalytics(app);
    }

    return (
        <React.Fragment>
            <Header />
            <div>
                <div className="container mt-main">
                    <main className="flex-shrink-0">
                        <div className="col-sm-12 mb-3 text-center">
                            <AdSense.Google
                                client='ca-pub-2605486701851317'
                                slot='9646212693'
                                style={{ display: 'block' }}
                                format='auto'
                                responsive='true'
                                layoutKey='-gw-1+2a-9x+5c'
                            />
                        </div>
                        {/* // auto full width responsive ads */
                            // loaded ?: ''
                        }
                        <Routes {...props} />
                    </main>
                </div>
            </div>
            <Footer />
        </React.Fragment>
    )
}

export default App