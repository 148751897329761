export const LANGUAGES = {
    pt: {
        urlLang: 'pt',
        code: 'pt-BR'
    },
    es: {
        urlLang: 'es',
        code: 'es-ES'
    },
    fr: {
        urlLang: 'fr',
        code: 'fr-FR'
    },
    it: {
        urlLang: 'it',
        code: 'it-IT'
    },
    ru: {
        urlLang: 'ru',
        code: 'ru-RU'
    },
    de: {
        urlLang: 'de',
        code: 'de-DE'
    },
    en: {
        urlLang: 'en',
        code: 'en-US'
    },
    nl: {
        urlLang: 'nl',
        code: 'nl-NL'
    },    
    default: 'en'
}