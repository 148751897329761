import React from 'react'
import { Routes, Route } from 'react-router'
import { LANGUAGES } from '../_i18n/languages';
import loadable from '@loadable/component'
import {
    useLocation,
    // useNavigate,
    useParams
} from "react-router-dom";

// import About from '../about/About'
const About = loadable(() => import('../about/About'))
const Terms = loadable(() => import('../terms/Terms'))
const Privacy = loadable(() => import('../privacy/Privacy'))
const NotFound = loadable(() => import('../notFound/NotFound'))
const AlarmClock = loadable(() => import('../alarmclock/AlarmClock'))
const Stopwatch = loadable(() => import('../stopwatch/Stopwatch'))
const Timer = loadable(() => import('../timer/Timer'))


const MultiLanguageRoute = ({ Component, location, custom }) => {
    const params = useParams();
    //console.log('params->'+JSON.stringify(params));
    //console.log(location)

    let hasLang = params.lang
    hasLang = hasLang ? hasLang : 'en'
    // let pathParam1 = location.pathname.split('/')[1]
    // let pathParam2 = props.location.pathname.split('/')[2]
    //let pathParam3 = location.pathname.split('/')[3]
    const is404Page = location.pathname.split('/')[3]
    //const is404Page = (pathParam3 && pathParam1 !== 'countdown-timer') ? true : pathParam3 && pathParam3.indexOf('timer') === -1
    //const isBasePathWithoutLang = props.location.pathname === "/"
    const currentLanguage = LANGUAGES[hasLang]

    //if(isBasePathWithoutLang)  return <Redirect to={`/${defaultLanguage}`} />
    //if(!hasLang && !is404Page) return <Redirect to={`/${defaultLanguage}`} />
    if (!currentLanguage || is404Page) return <NotFound location={location} staticContext={location.staticContext}/>

    return <Component location={location} custom={custom} /> //<Route component={component} />
}

const Router = (props) => {
    const location = useLocation();
    location.staticContext = props.staticContext;
    //console.log(location); 
    return (
        <React.Fragment>
            <Routes>
                <Route exact path='/' element={<AlarmClock location={location} />} />
                <Route path='/about' element={<About />} />
                <Route path='/terms' element={<Terms />} />
                <Route path='/privacy' element={<Privacy />} />
                <Route path='/countdown-timer/:lang?' element={<MultiLanguageRoute Component={Timer} location={location} />} />
                <Route path='/stopwatch/:lang?' element={<MultiLanguageRoute Component={Stopwatch} location={location} />} />
                <Route path='/online-clock-alarm/:lang?' element={<MultiLanguageRoute Component={AlarmClock} location={location} custom={{
                    'title': 'alarmclock.online-clock-alarm.seo.title',
                    'description': 'alarmclock.online-clock-alarm.seo.description',
                    'h1': 'alarmclock.online-clock-alarm.h1',
                    'desc1': 'alarmclock.online-clock-alarm.desc.1'
                }}
                />} />
                <Route path='/online-alarm/:lang?' element={<MultiLanguageRoute Component={AlarmClock} location={location} custom={{
                    'title': 'alarmclock.online-alarm.seo.title',
                    'description': 'alarmclock.online-alarm.seo.description',
                    'h1': 'alarmclock.online-alarm.h1',
                    'desc1': 'alarmclock.online-alarm.desc.1'
                }}
                />} />
                <Route path='/set-alarm-clock/:lang?' element={<MultiLanguageRoute Component={AlarmClock} location={location} custom={{
                    'title': 'alarmclock.set-alarm-clock.seo.title',
                    'description': 'alarmclock.set-alarm-clock.seo.description',
                    'h1': 'alarmclock.set-alarm-clock.h1',
                    'desc1': 'alarmclock.set-alarm-clock.desc.1'
                }}
                />} />
                <Route path='/my-alarm-clock/:lang?' element={<MultiLanguageRoute Component={AlarmClock} location={location} custom={{
                    'title': 'alarmclock.my-alarm-clock.seo.title',
                    'description': 'alarmclock.my-alarm-clock.seo.description',
                    'h1': 'alarmclock.my-alarm-clock.h1',
                    'desc1': 'alarmclock.my-alarm-clock.desc.1'
                }}
                />} />
                <Route path='/extremely-loud-alarm-clock/:lang?' element={<MultiLanguageRoute Component={AlarmClock} location={location} custom={{
                    'title': 'alarmclock.extremely-loud-alarm-clock.seo.title',
                    'description': 'alarmclock.extremely-loud-alarm-clock.seo.description',
                    'h1': 'alarmclock.extremely-loud-alarm-clock.h1',
                    'desc1': 'alarmclock.extremely-loud-alarm-clock.desc.1'
                }}
                />} />
                <Route path='/free-online-alarm-clock/:lang?' element={<MultiLanguageRoute Component={AlarmClock} location={location} custom={{
                    'title': 'alarmclock.free-online-alarm-clock.seo.title',
                    'description': 'alarmclock.free-online-alarm-clock.seo.description',
                    'h1': 'alarmclock.free-online-alarm-clock.h1',
                    'desc1': 'alarmclock.free-online-alarm-clock.desc.1'
                }}
                />} />
                <Route path='/despertador/:lang?' element={<MultiLanguageRoute Component={AlarmClock} location={location} custom={{
                    'title': 'alarmclock.despertador.seo.title',
                    'description': 'alarmclock.despertador.seo.description',
                    'h1': 'alarmclock.despertador.h1',
                    'desc1': 'alarmclock.despertador.desc.1'
                }}
                />} />                
                <Route path='/cloud-alarm/:lang?' element={<MultiLanguageRoute Component={AlarmClock} location={location} custom={{
                    'title': 'alarmclock.cloud-alarm.seo.title',
                    'description': 'alarmclock.cloud-alarm.seo.description',
                    'h1': 'alarmclock.cloud-alarm.h1',
                    'desc1': 'alarmclock.cloud-alarm.desc.1'
                }}
                />} />     
                <Route path='/alarm-clock-online/:lang?' element={<MultiLanguageRoute Component={AlarmClock} location={location} custom={{
                    'title': 'alarmclock.alarm-clock-online.seo.title',
                    'description': 'alarmclock.alarm-clock-online.seo.description',
                    'h1': 'alarmclock.alarm-clock-online.h1',
                    'desc1': 'alarmclock.alarm-clock-online.desc.1'
                }}
                />} />     
                <Route path='/online-clock/:lang?' element={<MultiLanguageRoute Component={AlarmClock} location={location} custom={{
                    'title': 'alarmclock.online-clock.seo.title',
                    'description': 'alarmclock.online-clock.seo.description',
                    'h1': 'alarmclock.online-clock.h1',
                    'desc1': 'alarmclock.online-clock.desc.1'
                }}
                />} />     
                <Route path='/set-alarm/:lang?' element={<MultiLanguageRoute Component={AlarmClock} location={location} custom={{
                    'title': 'alarmclock.set-alarm.seo.title',
                    'description': 'alarmclock.set-alarm.seo.description',
                    'h1': 'alarmclock.set-alarm.h1',
                    'desc1': 'alarmclock.set-alarm.desc.1'
                }}
                />} />     
                <Route path='/loud-alarm-clock/:lang?' element={<MultiLanguageRoute Component={AlarmClock} location={location} custom={{
                    'title': 'alarmclock.loud-alarm-clock.seo.title',
                    'description': 'alarmclock.loud-alarm-clock.seo.description',
                    'h1': 'alarmclock.loud-alarm-clock.h1',
                    'desc1': 'alarmclock.loud-alarm-clock.desc.1'
                }}
                />} />     
                <Route path='/:lang' element={<MultiLanguageRoute Component={AlarmClock} location={location} />} />
                <Route path='*' element={<NotFound {...props} />} />
            </Routes>
        </React.Fragment>
    )
}

export default Router